import React, { useState, useRef, useEffect } from "react"
import Col, { ColProps } from "../Col/Col";
import Row, { RowProps } from "../Row/Row";
import "./Reviews.scss"
import Review from "../Review/Review";
import Title from '@/components/Title/Title';
import Button from "../Button/Button";

import review1 from "../../img/review1.jpg"
import review2 from "../../img/review2.jpg"
import review3 from "../../img/review3.jpg"
import review4 from "../../img/review4.jpg"
import review5 from "../../img/review5.jpg"
import review6 from "../../img/review6.jpg"

import arrowRight from "../../img/arrowRight.svg"
import arrowLeft from "../../img/arrowLeft.svg"

interface ReviewsProps {
  className?: string
  id?: string
  name?: string
  text?: string
  image?: string
}

const Reviews = ({
  className,
  id,
  name,
  text,
  image
} : ReviewsProps) => {


return (

    <Row className="reviews__wrap">
      <div className="reviews__arrows">
        {/*<img className="reviews__arrow" src={arrowLeft} onClick={isFirst? handleThird : handleFirst} />
        <img className="reviews__arrow" src={arrowRight} />
        */}
      </div>

      <Review
        id="review__1"
        link="https://player.vimeo.com/video/609028016"
        image={review1}
        className="active"
        name="Enver Mehmet"
        text="I feel more abundant & my relationships are now more fulfilling. This program will serve me for the rest of my life."
      />
      <Review
        id="review__2"
        link="https://player.vimeo.com/video/707764108"
        image={review2}
        className="active"
        name="Craig Stewart"
        text="Jon & Vash always help me discover the root cause of problems and help me overcome them."
      />
      <Review
        id="review__3"
        link="https://player.vimeo.com/video/710762777"
        image={review3}
        className="active portrait"
        name="Tyrelle Anderson-Brown"
        text="Before starting with Jon, it was tough, I wasn't making much money, I didn't have any routines or systems."
      />

    </Row>



  )
};

export default Reviews;
