import React, { useState, useRef, useEffect } from "react"
import "../App.scss";
import { navigate } from "gatsby"
import { Link } from "gatsby"
import Section from "../components/Section/Section";
import Row from "../components/Row/Row";
import Col from "../components/Col/Col";
import CtaBar from "../components/CtaBar/CtaBar";
import Review from "../components/Review/Review";
import Reviews from "../components/Reviews/Reviews";
import Nav from "../components/Nav/Nav";
import Footer from "../components/Footer/Footer";
import Button from "../components/Button/Button";
import video from "../img/video.mp4"
import imageQuestion1 from "../img/q1.jpg"
import blurText from "../img/blurText.png"
import circle from "../img/circle.svg"
import ico1 from "../img/ico1.svg"
import ico2 from "../img/ico2.svg"
import ico3 from "../img/ico3.svg"
import ico4 from "../img/ico4.svg"
import ico5 from "../img/ico5.svg"
import ico6 from "../img/ico6.svg"
import playIcon from "../img/playIcon.svg"
import heroImage from "../img/event3.jpg"

import review1 from "../img/review1.jpg"
import review2 from "../img/review2.jpg"
import review3 from "../img/review3.jpg"



// data

{/*

const pos = { x : 0, y : 0 };

const saveCursorPosition = function(x, y) {
    pos.x = (x / window.innerWidth).toFixed(2);
    pos.y = (y / window.innerHeight).toFixed(2);
    document.documentElement.style.setProperty('--x', pos.x);
    document.documentElement.style.setProperty('--y', pos.y);
}

document.addEventListener('mousemove', e => { saveCursorPosition(e.clientX, e.clientY); })

*/}

{/*

const one = document.getElementById('hero');

window.addEventListener('scroll', () => {
  let scrollY = window.scrollY;
  document.documentElement.style.setProperty('--scrollPosition', scrollY);
})

*/}


// markup
const IndexPage = () => {

  const [isAutoplay, setIsAutoplay] = useState(false);

  const handleButtonClick = () => {
    navigate("#mainVideo")
    setIsAutoplay(prev => !prev)
  }

  return (
    <main>
      <Nav />
      <Section className="hero" id="hero">
        <Row>
          <Col className="hero__text">
            <h1>
              <span>We help entrepreneurs master their mindset and&nbsp;become </span>
              <div className="ulWrap">
                <ul className="content__container__list">
                  <li className="content__container__list__item">Happy</li>
                  <li className="content__container__list__item">Wealthy</li>
                  <li className="content__container__list__item">Successful</li>
                  <li className="content__container__list__item">Free</li>
                  <li className="content__container__list__item">Fearless</li>
                  <li className="content__container__list__item">Proud</li>
                  <li className="content__container__list__item">Fulfilled</li>
                  <li className="content__container__list__item">Happy</li>
                </ul>
              </div>
            </h1>
            <Button type="secondary" text="Get Started" link="https://www.7days7figures.com/join" />
          </Col>
          <Col size="40" className="hero__right">

            <div className="hero__play">
              <a onClick={handleButtonClick} id="playTarget" className="hero__play__icon">
                <img src={playIcon} />
              </a>
              <div className="hero__play__text">
                <h4>play video</h4>
                <p>The Mindtrepreneur story</p>
              </div>
            </div>

            <img className="hero__image" src={heroImage} />
          </Col>
        </Row>
      </Section>

      <Section className="fullHeight introduction">
        <Row>
          <Col sal="slide-up" delay="1000" duration="1000" easing="ease">
            <h2>The mindrepreneur story</h2>
            <p className="p30">Mindtrepreneur was founded by Jon & Vash back in February of 2021 after they discovered a huge problem in the entrepreneur space costing entrepreneurs their happiness, success and ultimately achieving their highest potential. Since then, Mindtrepreneur has gone on to impact and transform the lives of over 500 entrepreneurs from 30 countries.</p>
          </Col>
        </Row>

        <Row>
          <div className="video-inner" id="mainVideo">
            <iframe className="mainVideo" src={(isAutoplay) ? ("https://player.vimeo.com/video/721173844?autoplay=1&loop=1") : ("https://player.vimeo.com/video/721173844")} allow="autoplay; fullscreen" />
          </div>
        </Row>


      </Section>

      {/*

      <Section>

        <Row className="stickySide">

          <Col size="50">
            <h2>The mindtrepreneur philosohophy</h2>
          </Col>
          <Col size="50">

            <div data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000" data-sal-easing="ease" className="stickySide__item">
              <h6>01</h6>
              <h5>Master yourself first</h5>
                <p className="stickySide__text">
                  Mindtrepreneur believe for an entrepreneur to master their life and business
                  they must first master themself. The person you are, the character traits
                  you have, the beliefs you live by are going to determine the results you achieve
                  in your life and business. The entrepreneur industry has confused entrepreneurs by
                  making them believe they simply need information to succeed and that money and success
                  will bring them happiness and fulfilment
                </p>
              </div>

              <div data-sal="slide-up" data-sal-delay="300" data-sal-duration="1000" data-sal-easing="ease" className="stickySide__item">
                <h6>02</h6>
                <h5>Find the right coach for you</h5>
                <p className="stickySide__text">
                  For the entrepreneurs beginning on their journey, they go through course after course,
                  invest in coach after coach and still they don’t ever attain the results they desired.
                  And for the entrepreneurs who eventually succeed, they are met with feelings of emptiness
                  and unfulfillment realising that the money and success did not bring them the feeling they desired.
                </p>
              </div>

              <div data-sal="slide-up" data-sal-delay="500" data-sal-duration="1000" data-sal-easing="ease" className="stickySide__item">
                <h6>03</h6>
                <h5>Achieve more</h5>
                <p className="stickySide__text">
                  The only way to change this, is for entrepreneurs to first, focus on their mindset and ultimately
                  become a #Mindtrepreneur. Over the years, mindtrepreneur has distilled it’s teachings down into a
                  simple three-step process that has proven itself over and over again. For an entrepreneur to achieve
                  more & become more, they must evolve into that person.
                </p>
              </div>

          </Col>
        </Row>

      </Section>


      <Section className="questions">
        <Row>
          <h2>Answer these questions</h2>
          <Col size="50"><img src={imageQuestion1}/></Col>
        </Row>
        <Row>

          <Col size="50">
            <h6>01</h6>
            <p className="question p30">What is your ultimate goal in life right now?</p>
          </Col>
          <Col size="50">
            <h6>02</h6>
            <p className="question p30">What type of person would you be to accomplish that?</p>
          </Col>
        </Row>
        <Row>
          <p>
              I guarantee, the person you are today is not the same person who will
              accomplish that goal, so we must become that person first and naturally
              we will attract the goal to us.
          </p>
        </Row>
        <img className="circle" src={circle} />
      </Section>

      */}


      <Section className="change">
        <Row>
          <Col size="40">
            <h2>We must change how we</h2>
          </Col>
        </Row>

        <Row>
          <Col className="change__item" size="25" sal="slide-up" delay="300" duration="1000" easing="ease">
            <div>
              <h6>01</h6>
              <h3>Think</h3>
            </div>
            <p>Our perspectives, beliefs and world views will determine how we feel</p>
          </Col>
          <Col className="change__item" size="25" sal="slide-up" delay="500" duration="1000" easing="ease">
            <div>
              <h6>02</h6>
              <h3>Feel</h3>
            </div>
            <p>The emotions and feelings we experience will determine how we act</p>
          </Col>
          <Col className="change__item" size="25" sal="slide-up" delay="700" duration="1000" easing="ease">
            <div>
              <h6>03</h6>
              <h3>Act</h3>
            </div>
            <p>And the way we act, will determine the results we experience in life</p>
          </Col>
        </Row>
      </Section>

      <Section>
        <Row>
          <h2>The Six Pillars of Mindtrepreneur</h2>
        </Row>
        <Row>
          <Col sal="slide-up" delay="300" duration="1000" easing="ease" size="33" className="card">
            <img className="card__ico" src={ico1} />
            <h4>Feel Truly Proud, Fulfilled & Happy Within Yourself</h4>
            <p>
              Remove feelings of unworthiness, sadness & unfulfillment.
              Become your own best friend and truly love yourself. Master
              your emotions and feel in complete control of your life.
            </p>
          </Col>

          <Col sal="slide-up" delay="500" duration="1000" easing="ease" size="33" className="card">
            <img className="card__ico" src={ico2} />
            <h4>Eliminate Your Fears, Doubts & Limiting Beliefs</h4>
            <p>
              Remove fears of failing, rejection & fear of what others think.
              ​No more self-doubt or self-sabotage. ​Replace limiting beliefs
              for powerful beliefs that serve you.
            </p>
          </Col>

          <Col sal="slide-up" delay="700" duration="1000" easing="ease" size="33" className="card">
            <img className="card__ico" src={ico3} />
            <h4>Master Yourself & Become The Best Version of YOU</h4>
            <p>
              Master yourself & be able to achieve anything you set your mind to. ​
              Eliminate that inner voice that tries to bring you down. Become an
              unstoppable person of character.
            </p>
          </Col>
        </Row>

        <Row>
          <Col sal="slide-up" delay="300" duration="1000" easing="ease" size="33" className="card">
            <img className="card__ico" src={ico4} />
            <h4>Accomplish Your Goals & Plans With Ease</h4>
            <p>
            Create plan that will ensure you will hit your goals.
            With our proven formula, achieving our goals is inevitable.
            Start our 90-Day Challenge to transform yourself and your life.
            </p>
          </Col>

          <Col sal="slide-up" delay="500" duration="1000" easing="ease" size="33" className="card">
            <img className="card__ico" src={ico5} />
            <h4>Gain Control of Your Habits & Routines</h4>
            <p>
              ​Make it automatic & effortless to take action.
              ​Automate empowering behaviours that benefit you & fuel you with confidence.
              Build powerful routines & structure to boost productivity & focus.
            </p>
          </Col>

          <Col sal="slide-up" delay="700" duration="1000" easing="ease" size="33" className="card">
            <img className="card__ico" src={ico6} />
            <h4>Build clarity, certainty & confidence</h4>
            <p>
            Create plans & visions that give you clarity & motivation to make massive action.
            ​Eliminate the feelings of confusion & overwhelm.
            ​Truly feel unstoppable as you work towards achieving your goals.
            </p>
          </Col>
        </Row>
        <Row>
          <Button text="Get Started" type="secondary" link="https://www.7days7figures.com/join" />
        </Row>
      </Section>

      <Section className="reviews reviewsThirds">
        <Row>
          <h2>Our happy clients</h2>
        </Row>
        <Row>
          <Reviews />
        </Row>
        <Row>
          <Button type="secondary" link="/testimonials" text="See all testimonials" />
        </Row>
      </Section>

      <CtaBar
        title="Ready to Transform Your Life & Business?"
        linkText="Start now!"
        link="https://www.7days7figures.com/join"
      />

      <Footer/>

    </main>
  )
}

export default IndexPage
